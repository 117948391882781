@font-face {
  font-family: 'Avenir Book';
  src: local('Avenir Book'), url(./Avenir-Book.woff) format('woff');
}

@font-face {
  font-family: 'Avenir Medium';
  src: local('Avenir Medium'), url(./Avenir-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Avenir Heavy';
  src: local('Avenir Heavy'), url(./Avenir-Heavy.woff) format('woff');
}

@font-face {
  font-family: 'Avenir Black';
  src: local('Avenir Black'), url(./Avenir-Black.woff) format('woff');
}
