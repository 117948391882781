$background-color: #FAFAFA;
$background-color-input: #EAEAEA;
$black: #000000;
$white: #ffffff;

$input-border-active: #3182ce;

$primary-color: #1B7291;
$primary-color-light: #66B4D0;
$primary-color-disabled: #CEE5ED;

$secondary-color: #F7921C;
$secondary-color-light: #F2AE5A;
$secondary-color-disabled: #FFE5C2;

$gray-100: #EAEAEA;
$gray-200: #D1D1D1;
$gray-300: #ADADAD;
$gray-400: #707070;

$status-success: #489B4B;
$status-warning: #CE7915;
$status-error: #C11E1E;
$status-info: #248EB4;
