.react-tel-input {
  width: 100%;

  .flag-dropdown {
    border: 1px solid $background-color-input;
  }

  .form-control {
    background: $background-color-input;
    border: 2px solid transparent;
    border-color: transparent;
    transition: all 0.2s;
    font-size: 12pt;
    height: 2.5rem;
    width: 100%;
    outline: 0;

    &:focus {
      background: transparent;
      border-color: $input-border-active;
    }

    &.error {
      &:focus {
        background: transparent;
        border-color: $status-error !important;
      }
    }
  }
}
